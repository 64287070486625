import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="iris-diagnostik" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Irisdiagnose`}</h1>
      <h3 {...{
        "id": "irisdiagnose"
      }}>{`Irisdiagnose`}</h3>
      <p>{`Das Rezept aus dem Auge`}</p>
      <p>{`Die Irisdiagnose beruht auf der Erkenntnis, dass das Auge Spiegelbild von Körper und Seele ist. Aus farblichen, strukturellen und pigmentigen Veränderungen der Iris (Regenbogenhaut) können Rückschlüsse auf genetisch-konstitutionelle Schwächen, individuelle Anfälligkeiten für bestimmte Erkrankungen und Organschwächen gezogen werden.`}</p>
      <p>{`Der Irisdiagnostiker hat damit ein wunderbares Instrument an der Hand, ohne großen Aufwand Informationen und Hinweise auf Krankheitszusammenhänge zu bekommen, die häufig schon den Weg zu einer erfolgreichen, kausalen Therapie weisen..`}</p>
      <hr></hr>
      <p>{`Die Irisdiagnose ersetzt keine Laboruntersuchungen, hat aber in unserer naturheilkundlichen Praxis im Rahmen der Erstuntersuchung einen hohen Stellenwert als Hinweis- und Ergänzungs-Diagnostik und zur Klärung ursächlicher Zusammenhänge.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      